import {
  environmentConfig,
  isDevelopmentNodeEnvironment,
  isProductionNodeEnvironment,
} from "@src/appV2/environment";
import { getAppInfo } from "@src/appV2/lib";
// eslint-disable-next-line no-restricted-imports
import { getFirebaseSingleton } from "@src/lib/firebase/src";
import axios from "axios";
import type * as z from "zod";

import { type CbhAppInfo } from "../lib";
import { APP_V2_APP_EVENTS, logEvent } from "../lib/analytics";

export interface ApiResponse<T> {
  data: T;
  status: number;
  statusText: string;
}

export interface CommonApiParams<RequestSchema, ResponseSchema> {
  url: string;
  requestSchema?: z.ZodType<RequestSchema>;
  responseSchema: z.ZodType<ResponseSchema>;
  queryParams?: Record<string, any>;
}

interface ApiParams<RequestSchema, ResponseSchema>
  extends CommonApiParams<RequestSchema, ResponseSchema> {
  data?: RequestSchema;
}

const apiClient = axios.create({
  baseURL: environmentConfig.REACT_APP_BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  async (config) => {
    const firebaseAuth = getFirebaseSingleton();

    if (!firebaseAuth.currentUser) {
      return config;
    }

    const authHeader = await firebaseAuth.currentUser.getIdToken();

    // FIXME: Type error when spreading ...config.headers in return object below
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = authHeader;

    return {
      ...config,
      headers: config.headers,
    };
  },
  async (error) => {
    if (error instanceof Error) {
      throw error;
    }
  }
);

// Locally caching app info to avoid calling getAppInfo on every request
let cachedAppInfo: Awaited<CbhAppInfo> | undefined;
async function getOrCreateAppInfo() {
  cachedAppInfo ||= await getAppInfo();

  return cachedAppInfo;
}

/**
 * This interceptor is used to add the app info to the request headers.
 * This isn't covered by unit tests because these interceptors get called
 * when the module is initialized, meaning we can't reliably mock the modules used within them.
 */
apiClient.interceptors.request.use(async (config) => {
  const appInfo = await getOrCreateAppInfo();

  /* eslint-disable no-param-reassign */
  config.headers["App-Version"] = appInfo.version;
  config.headers["App-Build"] = appInfo.build;
  config.headers["App-OTA-Build-Id"] = appInfo.otaBuildId;
  /* eslint-enable no-param-reassign */
  return config;
});

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error instanceof Error) {
      throw error;
    }
  }
);

export async function get<RequestSchema, ResponseSchema>({
  url,
  queryParams,
  requestSchema,
  responseSchema,
}: CommonApiParams<RequestSchema, ResponseSchema>): Promise<ApiResponse<ResponseSchema>> {
  if (requestSchema) {
    requestSchema.parse(queryParams);
  }

  const response = await apiClient.get<ResponseSchema>(url, { params: queryParams });

  if (isDevelopmentNodeEnvironment()) {
    try {
      responseSchema.parse(response.data);
    } catch (error) {
      throw new Error(`${String(error)}: ${JSON.stringify({ url, queryParams })}`);
    }
  }

  if (isProductionNodeEnvironment()) {
    const result = await responseSchema.safeParseAsync(response.data);
    if (!result.success) {
      logEvent(APP_V2_APP_EVENTS.API_SCHEMA_VALIDATION_ERROR, {
        errors: result.error.errors,
        metadata: {
          url,
          method: "GET",
        },
      });
    }
  }

  return response;
}

export async function post<RequestSchema, ResponseSchema>({
  url,
  data,
  queryParams,
  requestSchema,
  responseSchema,
}: ApiParams<RequestSchema, ResponseSchema>): Promise<ApiResponse<ResponseSchema>> {
  if (requestSchema) {
    requestSchema.parse(data);
  }

  const response = await apiClient.post<ResponseSchema>(url, data, { params: queryParams });

  if (isDevelopmentNodeEnvironment()) {
    try {
      responseSchema.parse(response.data);
    } catch (error) {
      throw new Error(`${String(error)}: ${JSON.stringify({ url, data, queryParams })}`);
    }
  }

  if (isProductionNodeEnvironment()) {
    const result = await responseSchema.safeParseAsync(response.data);
    if (!result.success) {
      logEvent(APP_V2_APP_EVENTS.API_SCHEMA_VALIDATION_ERROR, {
        errors: result.error.errors,
        metadata: {
          url,
          method: "POST",
          queryParams,
        },
      });
    }
  }

  return response;
}

export async function put<RequestSchema, ResponseSchema>({
  url,
  data,
  queryParams,
  requestSchema,
  responseSchema,
}: ApiParams<RequestSchema, ResponseSchema>): Promise<ApiResponse<ResponseSchema>> {
  if (requestSchema) {
    requestSchema.parse(data);
  }

  const response = await apiClient.put<ResponseSchema>(url, data, { params: queryParams });

  if (isDevelopmentNodeEnvironment()) {
    try {
      responseSchema.parse(response.data);
    } catch (error) {
      throw new Error(`${String(error)}: ${JSON.stringify({ url, data, queryParams })}`);
    }
  }

  if (isProductionNodeEnvironment()) {
    const result = await responseSchema.safeParseAsync(response.data);
    if (!result.success) {
      logEvent(APP_V2_APP_EVENTS.API_SCHEMA_VALIDATION_ERROR, {
        errors: result.error.errors,
        metadata: {
          url,
          method: "PUT",
          queryParams,
        },
      });
    }
  }

  return response;
}

export async function patch<RequestSchema, ResponseSchema>({
  url,
  data,
  queryParams,
  requestSchema,
  responseSchema,
}: ApiParams<RequestSchema, ResponseSchema>): Promise<ApiResponse<ResponseSchema>> {
  if (requestSchema) {
    requestSchema.parse(data);
  }

  const response = await apiClient.patch<ResponseSchema>(url, data, { params: queryParams });

  if (isDevelopmentNodeEnvironment()) {
    try {
      responseSchema.parse(response.data);
    } catch (error) {
      throw new Error(`${String(error)}: ${JSON.stringify({ url, data, queryParams })}`);
    }
  }

  if (isProductionNodeEnvironment()) {
    const result = await responseSchema.safeParseAsync(response.data);
    if (!result.success) {
      logEvent(APP_V2_APP_EVENTS.API_SCHEMA_VALIDATION_ERROR, {
        errors: result.error.errors,
        metadata: {
          url,
          method: "PATCH",
          queryParams,
        },
      });
    }
  }

  return response;
}

export async function remove<RequestSchema, ResponseSchema>({
  url,
  data,
  requestSchema,
  responseSchema,
  queryParams,
}: ApiParams<RequestSchema, ResponseSchema>): Promise<ApiResponse<ResponseSchema>> {
  if (requestSchema) {
    requestSchema.parse(data);
  }

  const response = await apiClient.delete<ResponseSchema>(url, { params: queryParams });

  if (isDevelopmentNodeEnvironment()) {
    try {
      responseSchema.parse(response.data);
    } catch (error) {
      throw new Error(`${String(error)}: ${JSON.stringify({ url, data, queryParams })}`);
    }
  }

  if (isProductionNodeEnvironment()) {
    const result = await responseSchema.safeParseAsync(response.data);
    if (!result.success) {
      logEvent(APP_V2_APP_EVENTS.API_SCHEMA_VALIDATION_ERROR, {
        errors: result.error.errors,
        metadata: {
          url,
          method: "DELETE",
        },
      });
    }
  }

  return response;
}
