export const API_TYPES = {
  placement: "placement",
  workplace: "workplace",
  placementCandidate: "placement-candidate",
  placementApplication: "placement-application",
  interviewAvailability: "interview-availability",
  interview: "interview",
  interviewSettings: "interview-settings",
  paySurveyResponse: "pay-survey-response",
  sheet: "sheet",
  referral: "referral",
  worker: "worker",
  workplaceUser: "workplace-user",
  deepLink: "deep-link",
} as const;
